import React from "react";
import Layout from "../components/layout";
import "../styles/pages/policy.scss";
export default function PrivacyPolicy() {
  return (
    <Layout title="Privacy Policy">
      <h1 className="page-title">Privacy Policy</h1>
      <article>
        <section className="section">
          <p>
            This Privacy Policy outlines how Nookampalayam Link Road Residents
            Welfare Association (referred to as "we", "our", or "us") collects,
            uses, maintains, and protects information collected from residents
            (referred to as "you" or "your") of NLRRWA (referred to as "the
            community") in accordance with applicable data protection laws.
          </p>
        </section>
        <section className="section">
          <h2>1. Information We Collect:</h2>
          <p>
            We may collect personal identification information from residents in
            various ways, including, but not limited to, when residents visit
            our website, fill out a form, respond to a survey, or engage with
            our services. The information collected may include, but is not
            limited to, name, email address, phone number, and residential
            address.
          </p>
        </section>

        <section className="section">
          <h2>2. How We Use Collected Information:</h2>
          <p>
            We may use the information we collect from residents for the
            following purposes:
          </p>
          <ul>
            <li>To improve our services</li>
            <li>To personalize resident experience</li>
            <li>To send periodic emails</li>
          </ul>
        </section>

        <section className="section">
          <h2>3. Protection of Information:</h2>
          <p>
            We adopt appropriate data collection, storage, and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure, or destruction of personal
            information stored on our systems.
          </p>
        </section>

        <section className="section">
          <h2>4. Sharing Your Personal Information:</h2>
          <p>
            We do not sell, trade, or rent residents' personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding residents with our partners and trusted affiliates for the
            purposes outlined above.
          </p>
        </section>

        <section className="section">
          <h2>5. Compliance with Laws:</h2>
          <p>
            We will disclose any information collected if required to do so by
            law or in response to valid requests by public authorities.
          </p>
        </section>

        <section className="section">
          <h2>6. Changes to This Privacy Policy:</h2>
          <p>
            We have the discretion to update this privacy policy at any time.
            When we do, we will revise the updated date at the bottom of this
            page. We encourage residents to frequently check this page for any
            changes to stay informed about how we are helping to protect the
            personal information we collect. Residents acknowledge and agree
            that it is their responsibility to review this privacy policy
            periodically and become aware of modifications.
          </p>
        </section>

        <section className="section">
          <h2>7. Your Acceptance of These Terms:</h2>
          <p>
            By using our services, residents signify their acceptance of this
            policy. If residents do not agree to this policy, please do not use
            our services. Continued use of the services following the posting of
            changes to this policy will be deemed as acceptance of those
            changes.
          </p>
        </section>

        <section className="section">
          <h2>8. Contact Us:</h2>
          <p>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us at
            <a href="mailto:nlrrwa2018@gmail.com"> nlrrwa2018@gmail.com</a>.
          </p>
        </section>
        <p className="last-updated">
          This Privacy Policy was last updated on 12-Mar-2024.
        </p>
      </article>
    </Layout>
  );
}
